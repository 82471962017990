/** @jsxImportSource theme-ui */
import {
  ToggleFavorite,
  useFavorite,
} from "@bottlebooks/bottlebooks-site-bookmarks/src";
import AddToListButton from "@bottlebooks/bottlebooks-site-lists/src/components/AddToListButton";
import { TastingNoteButton } from "@bottlebooks/bottlebooks-site-tastingnotes/src";
import { Box, Flex } from "@bottlebooks/gatsby-design-system";
import { FragmentType, graphql, useFragment } from "~/gql";
import useLink from "../../useLink";
import {
  ProductHeaderDetails,
  ProductName,
  ProductProducer,
  hasDifferentExhibitor,
} from "../Product/ProductHeader.next";
import ProductLabel from "../Product/ProductLabel.next";
import ProductStand from "../Product/ProductStand.next";
import ProductImageCol from "../ProductPage/ProductImageCol";
import ProductNavigation from "../ProductPage/ProductNavigation";
import {
  ProductMainCol,
  ProductPageHeaderRow,
} from "../ProductPage/ProductPageHeader.next";
import { useEventData } from "../SiteSearch/EventDataProvider.next";
import useSiteConfig from "../useSiteConfig";
import WFSSpecialMentions from "../../WFSSpecialMentions";

export const fragment = graphql(`
  fragment EventProductPageHeaderRegisteredProduct on RegisteredProduct {
    productId
    ...ProductLabel_RegisteredProduct
    ...ProductHeaderDetails
    ...ProductHeaderDetails_RegisteredProduct
    ...HasDifferentExhibitor
    registration {
      ...ProductStand
      profile {
        name
      }
    }
    product {
      productId
      producer {
        name
      }
      ...ProductHeaderProductName
      ...ProductHeaderProducerName
      ...EventProductPageHeader
      ...ProductImageLarge
      bottleImage {
        publicId
        sourceUrl
      }
      labelImage {
        publicId
        sourceUrl
      }
      backLabelImage {
        publicId
        sourceUrl
      }
    }
  }

  fragment EventProductPageHeader on Product {
    productId
    producer {
      ...useLink_Producer
    }
  }

  # fragment EventProductPageHeader on Product {
  #   ...ProductImageLarge
  #   ...ProductStand
  #   ...ProductProducerName
  #   ...ProductName
  #   ...ProductHeaderDetails
  # }
`);

export default function EventProductPageHeader({
  product: data,
  standPath,
}: {
  product: FragmentType<typeof fragment>;
  standPath: string;
}) {
  const registeredProduct = useFragment(fragment, data);
  const product = registeredProduct.product;
  const link = useLink();
  const favorite = useFavorite(
    product && { type: "product", id: product.productId }
  );
  const siteConfig = useSiteConfig();
  const { products } = useEventData();
  const index = products.findIndex(
    (aProduct) => aProduct.productId === product.productId
  );
  const previous = index > 0 ? products[index - 1] : null;
  const next = index < products.length - 1 ? products[index + 1] : null;

  const hasOtherExhibitor = hasDifferentExhibitor({ data: registeredProduct });

  return (
    <Box sx={{ position: "relative" }}>
      <ProductNavigation previous={previous} next={next} />
      <ProductPageHeaderRow
        sx={{ justifyContent: "center", paddingTop: 4 }}
        direction={["column-reverse", null, "row"]}
        align="center"
      >
        <ProductImageCol product={product} />
        <ProductMainCol>
          <Flex
            direction="column"
            align="flex-start"
            sx={{
              height: [null, null, 480],
              justifyContent: "center",
              marginBottom: [4, null, 0],
            }}
          >
            {/* <ProductStand
              data={registeredProduct?.registration}
              to={standPath}
            /> */}
            {/* {hasOtherExhibitor ? ( */}
            <WFSSpecialMentions productId={registeredProduct.productId} />
            <ProductProducer
              data={product}
              // to={
              //   // Handle situation where we have imported products to Unspecified producer
              //   product.producer?.isUnspecifiedProducerReplaced
              //     ? link.exhibitor(product.producer)
              //     : link.producer(product.producer)
              // }
            />
            {/* ) : null} */}
            <ProductName as="h1" product={product} />
            <Box sx={{ marginLeft: -2, marginTop: 2, marginBottom: 2 }}>
              <TastingNoteButton.Large productId={product?.productId} />
            </Box>
            <ProductHeaderDetails
              data={registeredProduct}
              showMorePricingInfo={true}
            />
            {siteConfig.listsEnabled ? (
              <AddToListButton product={product} />
            ) : siteConfig.bookmarksEnabled ? (
              <ToggleFavorite
                sx={{
                  marginTop: 3,
                  marginLeft: -2,
                  padding: 0,
                  paddingRight: 3,
                }}
                isToggled={favorite.isSet}
                onClick={favorite.toggle}
              />
            ) : null}
            <ProductLabel data={registeredProduct} sx={{ marginBottom: 3.5 }} />
          </Flex>
        </ProductMainCol>
      </ProductPageHeaderRow>
    </Box>
  );
}
