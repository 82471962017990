import {
  Box,
  Col,
  Container,
  Flex,
  Grid,
  Label,
  SemiTitle,
  Text,
  Title,
} from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { FragmentType, useFragment as getFragment, graphql } from "~/gql";
import ContentRow from "../ContentRow";

const fragment = graphql(`
  fragment WinesFromSpainTop100Section on RegisteredProduct {
    aromaChart: customFieldValue(key: "aromaChart") {
      ... on TextFieldValue {
        value
      }
    }
    winesFromSpainMedalImage: customFieldValue(
      key: "winesFromSpainMedalImage"
    ) {
      ... on TextFieldValue {
        value
      }
    }
    winesFromSpainMedal: customFieldValue(key: "winesFromSpainMedal") {
      ... on TextFieldValue {
        value
      }
    }
    distributorInGermany: customFieldValue(key: "distributorInGermany") {
      ... on TextFieldValue {
        value
      }
    }
    distributorInUk: customFieldValue(key: "distributorInUk") {
      ... on TextFieldValue {
        value
      }
    }
    distributorInJapan: customFieldValue(key: "distributorInJapan") {
      ... on TextFieldValue {
        value
      }
    }
    distributorInUsa: customFieldValue(key: "distributorInUsa") {
      ... on TextFieldValue {
        value
      }
    }
    distributorInSwitzerland: customFieldValue(
      key: "distributorInSwitzerland"
    ) {
      ... on TextFieldValue {
        value
      }
    }
    distributorInNetherlands: customFieldValue(
      key: "distributorInNetherlands"
    ) {
      ... on TextFieldValue {
        value
      }
    }
    seekingDistributionIn: customFieldValue(key: "seekingDistributionIn") {
      ... on TextFieldValue {
        value
      }
    }
    wfsTop100SpecialMention: customFieldValue(key: "wfsTop100SpecialMention") {
      __typename
      ... on TextFieldValue {
        value
      }
    }
  }
`);

export default function WinesFromSpainTop100Section({
  data,
}: {
  data: FragmentType<typeof fragment>;
}) {
  const registeredProduct = getFragment(fragment, data);
  const { distribution, seekingDistributionInCountries } =
    parseDistribution(data);
  const medalText = registeredProduct.winesFromSpainMedalImage?.value?.includes(
    "icex_gg.png"
  )
    ? "Grand Gold"
    : registeredProduct.winesFromSpainMedalImage?.value?.includes("icex_g.png")
    ? "Gold"
    : "Nothing";
  return (
    <Container>
      <ContentRow>
        <Col flex sx={{ marginY: 3, paddingX: 3 }}>
          <Flex direction="column" gap={2}>
            <Box>
              <Label sx={{ marginBottom: 2 }}>Wines from Spain Top 100</Label>
              <Flex gap={2} align="center">
                <img
                  src={registeredProduct.winesFromSpainMedalImage?.value}
                  style={{ width: 100, objectFit: "contain" }}
                />

                <Box>
                  <Text style={{ fontWeight: "bold" }}>{medalText}</Text>
                  <Text>{registeredProduct.winesFromSpainMedal?.value}</Text>
                </Box>
              </Flex>
            </Box>
            <img
              style={{ width: "100%" }}
              src={registeredProduct.aromaChart?.value}
            />
          </Flex>
          <Distribution data={data} />
        </Col>
      </ContentRow>
    </Container>
  );
}

function Distribution({ data }: { data: FragmentType<typeof fragment> }) {
  const registeredProduct = getFragment(fragment, data);
  const { distribution, seekingDistributionInCountries } =
    parseDistribution(data);
  if (!distribution.length && !seekingDistributionInCountries?.length)
    return null;
  return (
    <Box sx={{ marginTop: 3 }}>
      <Title sx={{ marginBottom: 3 }}>
        <Trans comment="The title of the Brand distributors - either a list of countries or a list of importers per country.">
          Distribution
        </Trans>
      </Title>
      {distribution.length ? (
        <Grid columns={[2, 3, 4]} gap={2} style={{ marginBottom: 30 }}>
          {distribution.map(({ country, distributor }) => (
            <Box key={country}>
              <SemiTitle>{country}</SemiTitle>
              <Text>{distributor}</Text>
            </Box>
          ))}
        </Grid>
      ) : null}
      {seekingDistributionInCountries?.length ? (
        <Box>
          <SemiTitle>
            <Trans>Seeking distribution partners</Trans>
          </SemiTitle>
          <Text>{seekingDistributionInCountries?.join(", ")}</Text>
        </Box>
      ) : null}
    </Box>
  );
}

export function parseDistribution(
  registeredProduct: FragmentType<typeof fragment>
) {
  const data = getFragment(fragment, registeredProduct);
  const {
    distributorInGermany,
    distributorInUk,
    distributorInJapan,
    distributorInUsa,
    distributorInSwitzerland,
    distributorInNetherlands,
    seekingDistributionIn,
  } = data;
  const distributorsByCountry = {
    Germany: distributorInGermany?.value,
    "United Kingdom": distributorInUk?.value,
    Japan: distributorInJapan?.value,
    USA: distributorInUsa?.value,
    Switzerland: distributorInSwitzerland?.value,
    Netherlands: distributorInNetherlands?.value,
  };
  const distribution = Object.entries(distributorsByCountry)
    .filter((entry) => entry[1])
    .map((entry) => ({ country: entry[0], distributor: entry[1] }))
    .sort((a, b) => a.country.localeCompare(b.country));

  const seekingDistributionInCountries = seekingDistributionIn?.value
    ?.split("/")
    .map((country) => country.trim())
    .map((countryInGerman) => {
      if (countryInGerman === "Deutschland") return "Germany";
      if (countryInGerman === "Großbritannien") return "United Kingdom";
      if (countryInGerman === "Japan") return "Japan";
      if (countryInGerman === "USA") return "USA";
      if (countryInGerman === "Schweiz") return "Switzerland";
      if (countryInGerman === "Niederlande") return "Netherlands";
      return countryInGerman;
    })
    .sort();
  return { distribution, seekingDistributionInCountries };
}
