import { Text } from "@bottlebooks/gatsby-theme-base/src";
export default function WFSSpecialMentions({
  productId,
}: {
  productId: string;
}) {
  const specialMentionText = specialMentions[productId];
  if (!specialMentionText) return null;
  return (
    <Text sx={{ marginTop: 2 }}>
      <strong>
        <i>{specialMentionText}</i>
      </strong>
    </Text>
  );
}

const specialMentions: Record<string, string> = {
  "6780ebb9cd5c5e114b081ae1": "Best wine of the competition",
  "6780e411d1208c11463fe4a3": "Best Discovery Wine",
  "6780e3e3d1208c11463fe454": "Best Organic Wine",
};
