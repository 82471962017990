/** @jsxImportSource theme-ui */
import {
  Box,
  Flex,
  Label,
  SemiTitle,
  Text,
} from "@bottlebooks/gatsby-design-system";
import { Link } from "@bottlebooks/gatsby-theme-base/src";
// import { graphql } from "gatsby";
import React from "react";
import { RichText } from "..";
import getUrlFromPageReference from "../../helpers/getUrlFromPageReference";
import GridImage from "./GridImage";

/**
 *
 * @param {object} props
 * @param {object} props.image
 * @param {'cover' | 'contain'} props.variant
 * @param {Number} props.width
 * @param {string} props.projectId
 * @param {string} [props.label]
 * @param {string} [props.subtitle]
 * @param {string} [props.title]
 * @param {Number} [props.itemsInRowCount=3]
 * @param {object} props._rawText
 * @param {object} props.page
 * @returns
 */
export default function CardBlock({
  image,
  projectId,
  variant = "cover",
  width,
  label,
  subtitle,
  title,
  itemsInRowCount = 3,
  _rawText,
  page,
  ...rest
}) {
  const url = getUrlFromPageReference(page);
  const link = url?.includes("http") ? { href: url } : { to: url };
  return (
    <Link {...link} sx={{ cursor: "pointer" }} {...rest}>
      <Flex
        direction="column"
        sx={{
          border: "1px solid border",
          "& p": { margin: 0 },
          "&:hover": { "& img": { opacity: 0.9 } },
        }}
      >
        <Box
          sx={{
            position: "relative",
            marginBottom: 2,
          }}
        >
          <GridImage
            image={image}
            projectId={projectId}
            sx={{
              display: "block",
              width: "100%",
              marginX: "auto",
              objectFit: "cover",
            }}
            config={{
              ...(variant === "contain" && {
                fit: "fill",
                bg: "fff",
                crop: "center",
              }),
            }}
            alt={title}
          />
        </Box>

        {label && <Label sx={{ marginTop: 1 }}>{label}</Label>}
        {title && <SemiTitle sx={{ paddingTop: 2 }}>{title}</SemiTitle>}
        {subtitle && <Text sx={{ color: "black" }}>{subtitle}</Text>}
        {_rawText && (
          <React.Fragment>
            <Text sx={{ color: "black" }}>
              <RichText>{_rawText}</RichText>
            </Text>
            {/* We should only show a link when there is a link */}
            {url && <Text>More...</Text>}
          </React.Fragment>
        )}
      </Flex>
    </Link>
  );
}

// export const fragment = graphql`
//   fragment SanityCardBlockFragment on SanityCardBlock {
//     image {
//       ...ImageWithPreview
//     }
//     label
//     title
//     subtitle
//     variant
//     _rawText
//     page {
//       ...SanityPageReference
//     }
//   }
// `;
