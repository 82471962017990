/** @jsxImportSource theme-ui */
import { Box, Flex, Text } from "@bottlebooks/gatsby-theme-base/src";
import { FragmentType, graphql, useFragment } from "~/gql";
import { lighten } from "polished";

const fragment = graphql(`
  fragment ProductLabel_RegisteredProduct on RegisteredProduct {
    flightName
    productLabel
    registration {
      __typename
      ... on SingleRegistration {
        stand {
          name
          standTypeWithNumber
          roomName
        }
      }
    }
  }
`);

export default function ProductLabel({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const product = useFragment(fragment, data);
  const singleRegistration =
    product.registration.__typename === "SingleRegistration"
      ? product.registration
      : null;
  const badgeLabel = [product.productLabel].filter(Boolean).join(" ");
  if (!badgeLabel) return null;
  return (
    <Box
      sx={{
        paddingY: 1,
        paddingLeft: 1,
        paddingRight: 2,
        backgroundColor: lighten(0.53)("#dd250f"),
        borderRadius: 4,
        display: "inline-block",
        border: 1,
        borderColor: "primary",
      }}
      {...rest}
    >
      <Flex gap={2} align="center">
        <img
          src="https://www.prowein.de/cgi-bin/md_prowein/lib/all/lob/return_download.cgi/pwe1902_tm01_rgb01.jpg?ticket=g_u_e_s_t&bid=5980&no_mime_type=0"
          sx={{
            width: 50,
            height: 50,
            objectFit: "contain",
            objectPosition: "top",
          }}
        />
        <Box>
          <Text variant="smallest">Wines from Spain</Text>
          <Text variant="smallest">
            Open Tasting Area H11/K22 <strong>#{badgeLabel}</strong>
          </Text>
          {singleRegistration?.stand?.name ? (
            <Text variant="smallest">
              Meet producer at stand{" "}
              <strong>{singleRegistration?.stand?.name}</strong>
            </Text>
          ) : null}
        </Box>
      </Flex>
    </Box>
  );
}
